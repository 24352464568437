import clsx from 'clsx'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { FC, useEffect, useRef } from 'react'

gsap.registerPlugin(ScrollTrigger)

const paragraphs = [
  'Overwhelmed by RWA offers?',
  'Tired of having thousand tabs open for research?',
  'Trading in & out of RWA’s is a hassle?',
  'NewEra Finance makes RWAs accessible for you.',
  'The easiest way to explore & trade your RWA’s.',
]

const IntroductionSection: FC = () => {
  const paragraphRefs = useRef<HTMLDivElement[]>([])
  const cursorRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const wordsArray = paragraphs.map((text) => text.split(' '))
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: '#introduction-section',
        start: 'top top',
        end: '+=3000',
        scrub: true,
        pin: true,
        anticipatePin: 1,
        pinSpacing: true,
      },
    })

    wordsArray.forEach((words, paragraphIndex) => {
      words.forEach((_, wordIndex) => {
        timeline
          .to(
            `#word-${paragraphIndex}-${wordIndex}`,
            { color: 'white', fontWeight: 700, fontStyle: 'normal', duration: 0.5 },
            `+=0.1`
          )
          .to(cursorRef.current, {
            x: () => {
              const wordElementBoundaries = document
                .getElementById(`word-${paragraphIndex}-${wordIndex}`)
                ?.getBoundingClientRect()
              const parentElementBoundaries = paragraphRefs.current[paragraphIndex].getBoundingClientRect()
              return parentElementBoundaries && wordElementBoundaries
                ? wordElementBoundaries.width + wordElementBoundaries.x - parentElementBoundaries.x
                : 0
            },
            y: () => {
              const wordElementBoundaries = document
                .getElementById(`word-${paragraphIndex}-${wordIndex}`)
                ?.getBoundingClientRect()
              const parentElementBoundaries = paragraphRefs.current[paragraphIndex].getBoundingClientRect()
              return wordElementBoundaries ? parentElementBoundaries.top - wordElementBoundaries.y : 0
            },
          })
      })
    })

    return () => {
      timeline.scrollTrigger?.kill()
      timeline.kill()
    }
  }, [])

  const getStyledText = (text: string, paragraphIndex: number) =>
    text.split(' ').map((word, wordIndex) => (
      <span id={`word-${paragraphIndex}-${wordIndex}`} key={wordIndex} className="text-gray-500 inline-block mr-1.5">
        {word.charAt(0).toUpperCase() + word.slice(1)}
      </span>
    ))

  return (
    <section id="introduction-section" className="max-sm:px-2 h-screen py-[100px] max-sm:py-[50px] w-full relative">
      <div className="w-[963px] max-w-[calc(100%-20px)] mx-auto mt-56">
        <div
          id="scroll-container-intro"
          className="relative bg-black font-mono flex justify-center flex-col text-4xl max-sm:text-2xl"
        >
          {/* <div
            ref={cursorRef}
            className="absolute w-[2px] bg-white"
            style={{
              height: '1em',
              transform: 'translate(-50%, 0)',
              pointerEvents: 'none',
            }}
          ></div> */}

          {paragraphs.map((text, index) => (
            <div
              key={index}
              id={`paragraph-${index}`}
              ref={(el) => el && (paragraphRefs.current[index] = el)}
              className={clsx('relative italic', {
                'mt-20': index === 3,
                'mt-3': index !== 3,
              })}
            >
              {getStyledText(text, index)}
            </div>
          ))}

          <img src="/img/arrow-moving.svg" width={196} height={54} className="absolute -top-24 left-6" />
          <img
            src="/img/arrow-moving.svg"
            width={196}
            height={54}
            className="absolute -bottom-24 right-6 -scale-y-100"
          />
        </div>
      </div>
    </section>
  )
}

export default IntroductionSection
