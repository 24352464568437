import decentExchangePng from 'assets/images/landing-coins.png'
import IntroductionSection from 'components/Landing/IntroductionSection'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useEffect } from 'react'

gsap.registerPlugin(ScrollTrigger)

export default function Landing() {
  const soon = () => {
    window.location.href = '/#/swap'
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    const coins = gsap.utils.toArray<HTMLDivElement>('#icons > div')
    const allHeight = window.innerHeight * 1.25
    const tl = gsap
      .timeline({
        // yes, we can add it to an entire timeline!
        paused: false,
        scrollTrigger: {
          scrub: 0.5,
          start: 'top top',
          end: () => '+=' + allHeight,
        },
      })
      .to('#c1', { top: '50%', duration: 0.4 }, 0)
      .to('#c2', { top: '50%', duration: 0.4 }, 0)
      .to('#c3', { top: '50%', duration: 0.4 }, 0)
      .to('#c3', { duration: 0.5, opacity: 0 }, 0.35)
      .to('#c4', { duration: 0.5, scale: 1, opacity: 0.15 }, 0.2)
      .to('#c5', { duration: 0.5, scale: 1, opacity: 0.15 }, 0.3)
      .to('#c6', { duration: 0.5, scale: 1, opacity: 0.15 }, 0.4)

    coins.forEach((coin, index) => {
      tl.to(coin, { opacity: 1 }, 0.2 + index * 0.1)
    })

    const deTl = gsap
      .timeline({
        scrollTrigger: {
          trigger: '#decent-exchange-section',
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
        },
      })
      .to('#decent-exchange-els', { opacity: 1, duration: 0.15 }, 0.15)
      .to('#decent-exchange-els', { scale: 1, duration: 0.3 }, 0.1)
      .to('#decent-exchange-png', { opacity: 1, duration: 0.3 }, 0.15)
      .to('#decent-exchange-png', { scale: 2, duration: 1 }, 0.1)
      .to('#decent-exchange-png', { opacity: 0, duration: 0.3 }, 0.75)

    document.querySelectorAll<HTMLDivElement>('.usp-card-wrapper').forEach((card) => {
      const spotlight = card.querySelector<HTMLDivElement>('.spotlight')

      card.addEventListener('mousemove', (e) => {
        const rect = card.getBoundingClientRect()
        const x = e.clientX - rect.left
        const y = e.clientY - rect.top

        if (spotlight) {
          spotlight.style.top = `${y}px`
          spotlight.style.left = `${x}px`
        }
      })

      // card.addEventListener("mouseleave", () => {
      //   spotlight.style.transform = `translate(-50%, -50%)`;
      // });
    })

    return () => {
      tl.scrollTrigger?.kill()
      tl.kill()
      deTl.scrollTrigger?.kill()
      deTl.kill()
    }
  }, [])

  return (
    <>
      <section className="min-h-[65vh] w-screen relative">
        <div className="h-60 w-96 rounded-full bg-white blur-[190px] absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 max-sm:opacity-50"></div>

        {/* <img src="/img/circle.png" className="size-[600px] absolute top-full left-1/2 -translate-x-1/2 -translate-y-1/2" alt="" />
      <img src="/img/arrow.svg" className="px-[12px] py-[18px] bg-black rounded-[60px] top-full translate-y-[50px] left-0 right-0 mx-auto absolute" alt="" />
      <div className="size-[500px] rounded-full opacity-35 absolute top-full left-1/2 -translate-x-1/2 -translate-y-1/2 border border-white"></div>
      <div className="size-[600px] rounded-full opacity-25 absolute top-full left-1/2 -translate-x-1/2 -translate-y-1/2 border border-white"></div>
      <div className="size-[700px] rounded-full opacity-10 absolute top-full left-1/2 -translate-x-1/2 -translate-y-1/2 border border-white"></div> */}
        <div className="w-full h-1/2 absolute left-0 top-0 bg-[url(/img/pattern.png)] mix-blend-color-burn max-sm:hidden"></div>
        <div className="w-full h-1/2 absolute left-0 bottom-0 bg-[url(/img/pattern.png)] mix-blend-overlay backdrop-blur-[50px] z-10 max-sm:hidden"></div>
        <div className="hero absolute w-full left-0 top-1/2 -translate-y-1/2 text-center z-20 max-sm:px-[20px]">
          <div className="flex justify-center gap-2 items-center">
            <span className="text-[56px] text-white font-extralight max-sm:text-[20px] italic">The</span>
            <span className="text-[56px] text-white font-medium max-sm:text-[20px]">Secondary Market</span>
            <span className="text-[56px] text-white font-extralight max-sm:text-[20px] italic">For</span>
          </div>
          <div className="flex justify-center gap-2 items-center">
            <span className="text-[56px] text-white font-medium max-sm:text-[20px]">Real World</span>
            <img src="/img/globe.png" className="h-[52px] max-sm:h-[30px]" alt="" />
            <span className="text-[56px] text-white font-medium max-sm:text-[20px]">Assets</span>
          </div>
          <img src="/img/border.png" className="w-[1121px] mx-auto -mt-8 max-sm:hidden" />
        </div>
        <div className="absolute left-0 right-0 mx-auto bottom-[60px] max-sm:bottom-[100px] flex flex-col gap-4 w-[280px] z-10">
          <input
            onClick={soon}
            type="submit"
            value="Launch App"
            className="bg-[#202020] shadow-[0px_34px_74px_0px_#000] rounded-[60px] h-[56px] text-center max-sm:shadow-[0px_4px_114px_0px_rgba(118,5,232,0.63)] max-sm:bg-[conic-gradient(from_-6deg_at_100%_0%,#FFF_15.605940967798233deg,#FFF_117.24214553833008deg,#FF2748_235.38886070251465deg,#6200FF_312.7477169036865deg,#FF1A41_357.0234990119934deg)]"
          />
        </div>
      </section>
      <section className="h-[1024px] max-sm:h-[150vw] w-full relative">
        <img
          src="/img/tokens.png"
          id="c1"
          className="size-[414px] max-sm:w-1/2 max-sm:h-auto max-sm:hidden blur-[100px] absolute left-0 right-0 mx-auto top-0 -translate-y-1/2"
          alt=""
        />
        <img
          src="/img/tokens.png"
          id="c2"
          className="size-[414px] max-sm:w-1/2 max-sm:h-auto max-sm:!top-1/2 absolute left-0 right-0 mx-auto top-0 -translate-y-1/2"
          alt=""
        />
        <img
          src="/img/circle.png"
          id="c3"
          className="size-[584px] max-sm:w-[70%] max-sm:h-auto max-sm:hidden absolute left-0 right-0 mx-auto top-0 -translate-y-1/2"
          alt=""
        />
        <div
          id="c4"
          className="size-[554px] max-sm:w-[60%] max-sm:aspect-square max-sm:h-auto rounded-full opacity-0 scale-50 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-white"
        ></div>
        <div
          id="c5"
          className="size-[720px] max-sm:w-[70%] max-sm:aspect-square max-sm:h-auto rounded-full opacity-0 scale-50 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-white"
        ></div>
        <div
          id="c6"
          className="size-[870px] max-sm:w-[80%] max-sm:aspect-square max-sm:h-auto rounded-full opacity-0 scale-50 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-white"
        ></div>
        <div id="icons">
          <div className="size-[72px] max-sm:size-[30px] max-sm:top-[24%] absolute opacity-0 top-[117px] rounded-full bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] left-1/2 -translate-x-1/2 backdrop-blur-[30px] group cursor-pointer">
            <img
              src="/img/token.png"
              className="size-[40px] max-sm:size-[20px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full"
              alt=""
            />
            <span className="px-[20px] py-[12px] max-sm:hidden text-white absolute rounded-[60px] bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[60px] top-0 left-full -translate-y-[30px] translate-x-[5px] transition-all opacity-0 group-hover:opacity-100 group-hover:translate-x-[10px]">
              USDY
            </span>
          </div>

          <div className="size-[72px] max-sm:size-[30px] max-sm:left-auto max-sm:right-[20%] max-sm:top-[32%] max-sm:transform-none absolute opacity-0 top-[225px] left-1/2 translate-x-[220px] rounded-full bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[30px] group cursor-pointer">
            <img
              src="/img/token9.png"
              className="size-[40px] max-sm:size-[20px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full"
              alt=""
            />
            <span className="px-[20px] py-[12px] max-sm:hidden text-white absolute rounded-[60px] bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[60px] top-0 left-full -translate-y-[30px] translate-x-[5px] transition-all opacity-0 group-hover:opacity-100 group-hover:translate-x-[10px]">
              OpenEden
            </span>
          </div>

          <div className="size-[72px] max-sm:size-[30px] max-sm:right-auto max-sm:left-[20%] max-sm:top-[32%] max-sm:transform-none absolute opacity-0 top-[225px] right-1/2 -translate-x-[220px] rounded-full bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[30px] group cursor-pointer">
            <img
              src="/img/token11.png"
              className="size-[40px] max-sm:size-[20px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full"
              alt=""
            />
            <span className="px-[20px] py-[12px] max-sm:hidden text-white absolute rounded-[60px] bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[60px] top-0 left-full -translate-y-[30px] translate-x-[5px] transition-all opacity-0 group-hover:opacity-100 group-hover:translate-x-[10px]">
              USDC
            </span>
          </div>

          <div className="size-[72px] max-sm:size-[30px] max-sm:left-auto max-sm:right-[11%] max-sm:top-[50%] max-sm:translate-x-0 absolute opacity-0 top-1/2 -translate-y-1/2 left-1/2 translate-x-[320px] rounded-full bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[30px] group cursor-pointer">
            <img
              src="/img/token2.png"
              className="size-[40px] max-sm:size-[20px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full"
              alt=""
            />
            <span className="px-[20px] py-[12px] max-sm:hidden text-white absolute rounded-[60px] bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[60px] top-0 left-full -translate-y-[30px] translate-x-[5px] transition-all opacity-0 group-hover:opacity-100 group-hover:translate-x-[10px]">
              USDT
            </span>
          </div>

          <div className="size-[72px] max-sm:size-[30px] max-sm:right-auto max-sm:left-[11%] max-sm:top-[50%] max-sm:translate-x-0 absolute opacity-0 top-1/2 -translate-y-1/2 right-1/2 -translate-x-[320px] rounded-full bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[30px] group cursor-pointer">
            <img
              src="/img/token6.png"
              className="size-[40px] max-sm:size-[20px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full"
              alt=""
            />
            <span className="px-[20px] py-[12px] max-sm:hidden text-white absolute rounded-[60px] bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[60px] top-0 left-full -translate-y-[30px] translate-x-[5px] transition-all opacity-0 group-hover:opacity-100 group-hover:translate-x-[10px]">
              USDY
            </span>
          </div>

          <div className="size-[72px] max-sm:size-[30px] max-sm:left-auto max-sm:right-[20%] max-sm:bottom-[32%] max-sm:transform-none absolute opacity-0 bottom-[225px] left-1/2 translate-x-[220px] rounded-full bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[30px] group cursor-pointer">
            <img
              src="/img/token3.png"
              className="size-[40px] max-sm:size-[20px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full"
              alt=""
            />
            <span className="px-[20px] py-[12px] max-sm:hidden text-white absolute rounded-[60px] bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[60px] top-0 left-full -translate-y-[30px] translate-x-[5px] transition-all opacity-0 group-hover:opacity-100 group-hover:translate-x-[10px]">
              Backed Finance
            </span>
          </div>

          <div className="size-[72px] max-sm:size-[30px] max-sm:right-auto max-sm:left-[20%] max-sm:bottom-[32%] max-sm:transform-none absolute opacity-0 bottom-[225px] right-1/2 -translate-x-[220px] rounded-full bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[30px] group cursor-pointer">
            <img
              src="/img/token5.png"
              className="size-[40px] max-sm:size-[20px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full"
              alt=""
            />
            <span className="px-[20px] py-[12px] max-sm:hidden text-white absolute rounded-[60px] bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[60px] top-0 left-full -translate-y-[30px] translate-x-[5px] transition-all opacity-0 group-hover:opacity-100 group-hover:translate-x-[10px]">
              rUSDY
            </span>
          </div>

          <div className="size-[72px] max-sm:size-[30px] max-sm:bottom-[24%] absolute opacity-0 bottom-[117px] rounded-full bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] left-1/2 -translate-x-1/2 backdrop-blur-[30px] group cursor-pointer">
            <img
              src="/img/token10.png"
              className="size-[40px] max-sm:size-[20px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full"
              alt=""
            />
            <span className="px-[20px] py-[12px] max-sm:hidden text-white absolute rounded-[60px] bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[60px] top-0 left-full -translate-y-[30px] translate-x-[5px] transition-all opacity-0 group-hover:opacity-100 group-hover:translate-x-[10px]">
              USDM
            </span>
          </div>
        </div>
      </section>
      <IntroductionSection />
      <section id="decent-exchange-section" className="relative py-[200px] max-sm:pt-[50px] w-full">
        <div id="decent-exchange-els" className="w-full flex flex-col justify-center items-center scale-0 opacity-0">
          <h3 className="text-[38px] font-semibold max-w-4xl text-center px-6 max-sm:text-xl">
            A Permissioned Decentralized Exchange for the new Era of Finance
          </h3>
          <p className="text-xl opacity-50 px-6 max-sm:text-base mt-6 max-sm:mt-4">
            non-custodial, optimized trading, single interface
          </p>
          <a
            href="https://docs.newera.finance/"
            target="_blank"
            className="flex justify-center items-center mt-8 text-white bg-white/10 rounded-full font-semibold max-sm:text-[14px] max-sm:justify-center px-6 py-4 w-72"
            rel="noreferrer"
          >
            Learn More
          </a>
        </div>
        <img
          id="decent-exchange-png"
          src={decentExchangePng}
          className="w-[1600px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-0 opacity-0"
        />
      </section>
      <section className="py-[200px] max-sm:py-[50px] w-full">
        <div className="w-[900px] max-w-[calc(100%-20px)] mx-auto space-y-6 max-sm:space-y-2">
          <div className="flex gap-6 max-sm:gap-2">
            <div className="usp-card-wrapper group flex-shrink-0 w-[500px] max-sm:w-1/2 rounded-[28px] max-sm:rounded-[16px] h-[276px] max-sm:h-auto relative bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.1)] backdrop-blur-[30px] overflow-hidden">
              <div className="spotlight blur-[50px] absolute w-[200px] h-[200px] bg-white bg-opacity-30 rounded-full opacity-0 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-50"></div>
              <img
                src="/img/b1.png"
                className="max-sm:relative absolute right-0 max-h-full top-0 grayscale transition-all group-hover:grayscale-0"
                alt=""
              />
              <div className="absolute left-0 bottom-0 p-[32px] max-sm:p-[20px]">
                <span className="text-white opacity-50 max-sm:text-[14px]">Supporting</span>
                <p className="text-white font-medium text-[20px] max-sm:text-[16px]">All Coins</p>
              </div>
            </div>

            <div className="usp-card-wrapper group flex-1 rounded-[28px] max-sm:rounded-[16px] h-[276px] max-sm:h-auto relative bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.1)] backdrop-blur-[30px] overflow-hidden">
              <div className="spotlight blur-[50px] absolute w-[200px] h-[200px] bg-white bg-opacity-30 rounded-full opacity-0 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-50"></div>
              <img
                src="/img/b2.png"
                className="max-sm:relative absolute right-0 max-h-full top-0 grayscale transition-all group-hover:grayscale-0"
                alt=""
              />
              <div className="absolute left-0 bottom-0 p-[32px] max-sm:p-[20px]">
                <span className="text-white opacity-50 max-sm:text-[14px]">From</span>
                <p className="text-white font-medium text-[20px] max-sm:text-[16px]">All Chains</p>
              </div>
            </div>
          </div>

          <div className="flex gap-6 max-sm:gap-2">
            <div className="usp-card-wrapper group flex-shrink-0 w-[376px] max-sm:w-1/2 rounded-[28px] max-sm:rounded-[16px] h-[276px] max-sm:h-auto relative bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.1)] backdrop-blur-[30px] overflow-hidden">
              <div className="spotlight blur-[50px] absolute w-[200px] h-[200px] bg-white bg-opacity-30 rounded-full opacity-0 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-50"></div>
              <img
                src="/img/b3.png"
                className="max-sm:relative absolute right-0 max-h-full top-0 grayscale transition-all group-hover:grayscale-0"
                alt=""
              />
              <div className="absolute left-0 bottom-0 p-[32px] max-sm:p-[20px]">
                <span className="text-white opacity-50 max-sm:text-[14px]">Lowest</span>
                <p className="text-white font-medium text-[20px] max-sm:text-[16px]">Slippage</p>
              </div>
            </div>

            <div className="usp-card-wrapper group flex-1 rounded-[28px] max-sm:rounded-[16px] h-[276px] max-sm:h-auto relative bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.1)] backdrop-blur-[30px] overflow-hidden">
              <div className="spotlight blur-[50px] absolute w-[200px] h-[200px] bg-white bg-opacity-30 rounded-full opacity-0 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-50"></div>
              <img
                src="/img/b4.png"
                className="max-sm:relative absolute right-0 max-h-full top-0 grayscale transition-all group-hover:grayscale-0"
                alt=""
              />
              <div className="absolute left-0 bottom-0 p-[32px] max-sm:p-[20px]">
                <span className="text-white opacity-50 max-sm:text-[14px]">Lowest</span>
                <p className="text-white font-medium text-[20px] max-sm:text-[16px]">Min Investment</p>
              </div>
            </div>
          </div>

          <div className="flex gap-6 max-sm:gap-2">
            <div className="usp-card-wrapper group flex-shrink-0 w-[500px] max-sm:w-1/2 rounded-[28px] max-sm:rounded-[16px] h-[276px] max-sm:h-auto relative bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.1)] backdrop-blur-[30px] overflow-hidden">
              <div className="spotlight blur-[50px] absolute w-[200px] h-[200px] bg-white bg-opacity-30 rounded-full opacity-0 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-50"></div>
              <img
                src="/img/b5.png"
                className="max-sm:relative absolute right-0 max-h-full top-0 grayscale transition-all group-hover:grayscale-0"
                alt=""
              />
              <div className="absolute left-0 bottom-0 p-[32px] max-sm:p-[20px]">
                <span className="text-white opacity-50 max-sm:text-[14px]">VASP</span>
                <p className="text-white font-medium text-[20px] max-sm:text-[16px]">Regulated</p>
              </div>
            </div>

            <div className="usp-card-wrapper group flex-1 rounded-[28px] max-sm:rounded-[16px] h-[276px] max-sm:h-auto relative bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.1)] backdrop-blur-[30px] overflow-hidden">
              <div className="spotlight blur-[50px] absolute w-[200px] h-[200px] bg-white bg-opacity-30 rounded-full opacity-0 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-50"></div>
              <img
                src="/img/b6.png"
                className="max-sm:relative absolute right-0 max-h-full max-sm:translate-x-[5%] top-0 grayscale transition-all group-hover:grayscale-0"
                alt=""
              />
              <div className="absolute left-0 bottom-0 p-[32px] max-sm:p-[20px]">
                <span className="text-white opacity-50 max-sm:text-[14px]">Single</span>
                <p className="text-white font-medium text-[20px] max-sm:text-[16px]">Interface</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-[100px] max-sm:py-[50px] w-full">
        <div className="flex w-[900px] max-w-[calc(100%-20px)] mx-auto gap-[60px] items-center max-sm:flex-col-reverse max-sm:gap-[30px] max-sm:text-center">
          <div className="flex-1 space-y-6 relative max-sm:space-y-2 max-sm:px-[20px]">
            <img
              src="/img/elipse.png"
              className="absolute left-0 top-1/2 -translate-x-1/2 -translate-y-1/2 h-[700px] max-w-fit pointer-events-none"
              alt=""
            />
            <span className="text-white opacity-50 max-sm:text-[14px]">NewEra Finance</span>
            <h2 className="font-medium text-[38px] max-sm:text-[18px]">
              Onchain Marketplace for the New Era of Finance
            </h2>
            <p className="text-white opacity-50 max-sm:text-[14px]">
              Best Prices for RWA. Best User Experience. Trade Trustless, Fully Compliant. NewEra creates a unique
              environment to trade real-world assets.
            </p>
            <a
              href="/#/swap"
              className="flex gap-2 text-white font-medium items-center max-sm:text-[14px] max-sm:justify-center"
            >
              Launch App
              <img src="/img/union1.svg" className="h-[12px]" alt="" />
            </a>
          </div>
          <div className="w-[400px] flex-shrink-0 relative h-[500px] max-sm:h-[100vw]">
            <img src="/img/era.png" alt="" className="w-[600px] absolute left-1/2 -translate-x-1/2 max-w-fit" />
          </div>
        </div>
      </section>
      <footer className="py-[100px] max-sm:py-[50px] relative w-full">
        <img src="/img/elipse3.png" className="absolute bottom-0 w-full pointer-events-none" alt="" />
        <div className="w-full h-full absolute left-0 top-0 bg-[url(/img/pattern.png)] mix-blend-color-burn opacity-40 max-sm:hidden"></div>
        <img src="/img/elipse2.png" className="absolute top-0 left-[40%] -translate-x-1/2 h-[345px]" alt="" />
        <div className="w-[900px] max-w-[calc(100%-20px)] mx-auto space-y-[50px]">
          <div className="flex justify-between relative max-sm:flex-col max-sm:gap-2">
            <div className="flex flex-shrink-0 w-[652px] max-sm:w-full gap-6">
              <div className="size-[164px] max-sm:hidden flex justify-center items-center bg-black rounded-[40px]">
                <img src="/img/logo2.png" className="size-[124px]" alt="" />
              </div>
              <div className="rounded-[40px] bg-[rgba(0,0,0,0.30)] max-sm:bg-[rgba(255,255,255,8%)] backdrop-blur-[4px] flex gap-6 p-[20px] max-sm:w-full">
                <img src="/img/letter.png" className="max-h-[124px] max-sm:hidden" alt="" />
                <div className="flex flex-col gap-4 w-[236px] max-sm:w-full">
                  <input
                    type="text"
                    placeholder="Your email"
                    className="bg-[rgba(3,3,3,0.50)] rounded-[60px] h-[56px] text-center"
                  />
                  <input
                    type="submit"
                    value="Early Access"
                    className="bg-[#030303] shadow-[0px_34px_74px_0px_#000] rounded-[60px] h-[56px] text-center"
                  />
                </div>
              </div>
            </div>
            <ul className="space-y-[12px] py-[18px] max-sm:text-center">
              <li>
                <a
                  href="https://docs.newera.finance/legal"
                  className="text-white opacity-50 transition-all hover:opacity-100"
                >
                  Legal
                </a>
              </li>
              <li>
                <a
                  href="https://docs.newera.finance/legal/privacy-policy"
                  className="text-white opacity-50 transition-all hover:opacity-100"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="https://docs.newera.finance"
                  className="text-white opacity-50 transition-all hover:opacity-100"
                >
                  Doc
                </a>
              </li>
            </ul>
          </div>
          <div className="mx-auto w-[900px] max-w-[calc(100%-20px)] p-[20px] rounded-[60px] overflow-hidden bg-[rgba(255,255,255,0.04)] backdrop-blur-[10px] px-[24px] flex justify-between items-center">
            <img src="/img/copy.svg" className="h-[28px] max-sm:block max-sm:h-auto" alt="" />
            <ul className="flex gap-6 items-center max-sm:hidden">
              <li>
                <img src="/img/twitter.svg" alt="" />
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  )
}
